<template>
	<div class="wrap">
		<!--用户管理 -->
		<!-- 头部 -->
		<el-form ref="queryForm" :model="queryParams" size="small" tooltip-effect="dark" :inline="true">
			<el-form-item label="用户名:">
				<el-input v-model="queryParams.user_name" clearable style="width: 180px" @keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="登录账号:">
				<el-input v-model="queryParams.account" clearable style="width: 180px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>

			<el-form-item label="手机号码:">
				<el-input v-model="queryParams.user_phone" clearable style="width: 180px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			
			<el-form-item label="邮箱:">
				<el-input v-model="queryParams.email" clearable style="width: 180px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="性别">
				<el-select v-model="queryParams.gender" style="width: 180px" clearable>
					<el-option v-for="item in genderoptions" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
			
			<el-form-item label="状态">
				<el-select v-model="queryParams.identitystart" style="width: 180px" clearable>
					<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
			
				<el-form-item label="角色" :label-width="formLabelWidth" prop="competence">
					<el-select style="width: 180px" v-model="queryParams.identity_id" multiple placeholder="请选择角色">
						<el-option v-for="item in menuOptions" :key="item.id" :label="item.power_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>
		<!-- 新增按钮 -->
		<el-row :gutter="10">

			<el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
			</el-col>


			<el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="deletedata">删除</el-button>
			</el-col>

			<!-- 			<el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleAdd">清空</el-button>
			</el-col> -->

			<!-- 			<el-col :span="1.5">
				<el-button type="warning" plain icon="el-icon-download" size="mini" @click="countDowndata">导出
				</el-button>
			</el-col> -->
			<el-col :span="1.5">
				<el-button type="warning" plain icon="el-icon-refresh-left" size="mini" @click="handshua">刷新</el-button>
			</el-col>
		</el-row>
		<!-- 表格信息 -->
		<el-table v-loading="loading" :lazy="true" :data="tableData" style="width: 100%"
			:header-cell-style="{textAlign: 'center'}" :cell-style="{textAlign: 'center'}"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column prop="id" sortable label="id" />
			<el-table-column label="头像">
				<template slot-scope="scope">
					<el-avatar shape="square" :size="50" :src="scope.row.avatarUrl"></el-avatar>
				</template>
			</el-table-column>
			<el-table-column prop="user_name" label="昵称" />
			<el-table-column prop="account" label="登录账号" />
			<el-table-column label="用户状态">
				<template slot-scope="scope">
					<div>{{scope.row.identitystart == 1 ? '正常' :scope.row.identitystart == 2? '冻结':'未通过' }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="appname" width="200" label="平台名称" />
			<el-table-column prop="user_phone" label="手机号" />
			<el-table-column prop="ip" label="ip" width="200"  />
			<el-table-column prop="iplocal" label="ip地址" width="200" />
			<el-table-column label="性别">
				<template slot-scope="scope">
					<div>{{scope.row.gender == 1 ? '男' : scope.row.gender == 2? '女' : '未知' }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="createdAt" sortable label="创建时间" width="200" />
			<!-- <el-table-column prop="login_time" sortable label="登陆时间" width="200" /> -->
			<el-table-column label="操作" width="300" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="success" @click="handleMember(scope.row)">修改</el-button>
					<el-button size="mini" type="warning" @click="startUsingOrFreeze(scope.row)">
						{{scope.row.identitystart == 1 ? '冻结' : '正常' }}
					</el-button>
					<el-popconfirm style="margin-left: 10px;" confirm-button-text='确定' cancel-button-text='不用了'
						icon="el-icon-info" icon-color="red" title="确定要删除吗？" @confirm="handleDelete(scope.row.id)">
						<el-button size="mini" type="danger" slot="reference">删除</el-button>
						<!-- <el-button slot="reference">删除</el-button> -->
					</el-popconfirm>
					<!-- <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button> -->
				</template>

			</el-table-column>
		</el-table>
		<!-- 完整功能分页 -->
		<el-pagination :current-page="currentPage" :page-sizes="[10, 15, 30, 50, 100]" :page-size="queryParams.pageSize"
			layout="->, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" />

		<!-- 弹出框信息 -->
		<el-dialog title="剧本配置" :visible.sync="configurationstart" width="50%">
			<el-form ref="form" :model="form">
				<el-form-item label="用户:" :label-width="formLabelWidth" prop="name">
					<el-input ref="login_name" v-model="registerForm.jubenname" placeholder="请输入用户名称" name="login_name"
						type="text" tabindex="1" auto-complete="on"></el-input>
				</el-form-item>

				<!-- 表格信息 -->
				<el-table :lazy="true" :data="tableData" style="width: 100%" :header-cell-style="{textAlign: 'center'}"
					:cell-style="{textAlign: 'center'}" @selection-change="handleSelectionChange">>
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column prop="log_num" label="日志编号" />
					<el-table-column prop="module" label="系统模块" />
				</el-table>
				<!-- 完整功能分页 -->
				<el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="10"
					layout="->, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
					@current-change="handleCurrentChange" />
			</el-form>
			<!-- 			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="configurationstartButto">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</div> -->
		</el-dialog>
		<!-- 弹窗-添加/修改用户 -->
		<el-dialog :title="flag == true ? '添加用户': '修改用户' " :visible.sync="dialogFormVisible" width="50%">
			<el-form ref="form" :model="form" :rules="formRules">
				<!-- 用户名称 -->
				<el-form-item label="用户名称:" :label-width="formLabelWidth" prop="user_name">
					<el-input v-model="form.user_name" autocomplete="off" placeholder="请输入用户昵称" />
				</el-form-item>
				<!-- 登录账号 -->
				<el-form-item label="登录账号:" :label-width="formLabelWidth" prop="account">
					<el-input v-model="form.account" autocomplete="off" placeholder="请输入账号名称" />
				</el-form-item>
				<!-- 手机号码 -->
				<el-form-item label="手机号码:" :label-width="formLabelWidth" prop="user_phone">
					<el-input v-model="form.user_phone" autocomplete="off" placeholder="请输入手机号码" />
				</el-form-item>
				<!-- 用户密码 -->
				<el-form-item label="用户密码:" :label-width="formLabelWidth" prop="password">
					<el-input v-model="form.password" autocomplete="off" placeholder="请输入用户密码" />
				</el-form-item>
				<!-- 角色 -->
				<!-- 				<el-form-item label="角色:" :label-width="formLabelWidth" prop="identity_id">
					<el-select v-model="form.identity_id" style="width: 140px">
						<el-option v-for="item in menuOptions" :key="item.id" :label="item.power_name" :value="item.id" />
					</el-select>
				</el-form-item> -->

				<el-form-item label="角色" :label-width="formLabelWidth" prop="competence">
					<el-select style="width: 85%;" v-model="form.identity_id" multiple placeholder="请选择角色">
						<el-option v-for="item in menuOptions" :key="item.id" :label="item.power_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

				<!-- 状态 -->
				<el-form-item label="状态:" :label-width="formLabelWidth" prop="status">
					<el-radio-group v-model="form.identitystart">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="2">冻结</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="cancel">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// 系统用户相关的
	const {
		userlists,
		srole_status,
		del_user,
		user_save,
	} = require("@/api/systemuser");
	// 角色相关的
	const {
		role_listAll,
	} = require("@/api/role");
	export default {
		data() {
			// 自定义 tree 规则
			const validatePass = (rule, value, callback) => {
				const arr = this.$refs.menu.getCheckedKeys()
				if (arr.length === 0 || !arr) {
					callback(new Error('请选择功能权限设置'))
				} else {
					callback()
				}
			}
			return {
				// 选择的用户的角色
				competence: "",
				// 进来加载表单的样式
				loading: true,
				registerForm: {
					// 剧本名
					jubenname: '',
				},
				configurationstart: false,
				// 顶部表单需要的数据
				queryParams: {
					// 页码
					pageNo: 1,
					// 每页显示多少条
					pageSize: 10,
					user_name: undefined, //用户名
					email:undefined,//邮箱
					// start_time: '', //开始时间
					// end_time: '', //结束时间
					account: undefined, //登录账号
					user_phone: undefined, //手机号码
					identitystart: undefined, //状态
					identity_id:undefined,//所属的角色
					gender:undefined,//性别
				},
				genderoptions:[{
					id: 1,
					value: 1,
					name: '男'
				}, {
					id: 2,
					value: 2,
					name: '女'
				},{
					id: 3,
					value: 3,
					name: '未知'
				}],
				
				// 选择器 数据
				options: [{
					id: 1,
					value: 1,
					name: '启用'
				}, {
					id: 2,
					value: 2,
					name: '冻结'
				}],
				// 日期范围
				dateRange: [],
				// 表格数据
				tableData: [],
				// form 表单的lable属性 宽度
				formLabelWidth: '110px',
				// 数据总数量
				total: null,
				// 分页组件数据
				currentPage: 1,
				// 角色列表
				roleList: [],
				// 弹窗里面的 表单数据
				menuExpand: false,
				menuNodeAll: false,
				// 菜单列表
				menuOptions: [],
				// 表单参数
				form: {
					// 账号状态
					identitystart: "",
					// 用户名称
					user_name: "",
					// 登陆账号
					account: "",
					// 手机号
					user_phone: "",
					// 密码
					password: "",
					// 角色id
					role_id: 0,
				},
				defaultProps: {
					children: 'menu_list',
					label: 'name'
				},
				// 表单校验
				formRules: {
					user_name: [{
						required: true,
						message: '用户名不能为空',
						trigger: 'blur'
					}],
					account: [{
						required: true,
						message: '登录账号不能为空',
						trigger: 'blur'
					}],
					user_phone: [{
						required: true,
						pattern: /^1[3-9][0-9]{9}$/,
						message: '请输入正确的手机号',
						trigger: 'blur'
					}],
					identitystart: [{
						required: true,
						message: '状态不能为空',
						trigger: 'blur'
					}],
					// password: [{
					// 	required: true,
					// 	message: '密码不能为空',
					// 	trigger: 'blur'
					// }]
				},
				// 单选框 数据
				radio: null,
				// 是否显示 添加/修改弹窗
				dialogFormVisible: false,
				// 判断是新增 还是 修改
				flag: false,
				menu_access: []
			}
		},
		watch: {
			dateRange: function() {
				this.queryParams.start_time = this.dateRange && this.dateRange[0] || undefined
				this.queryParams.end_time = this.dateRange && this.dateRange[1] || undefined
				this.handleQuery()
			},
		},
		async created() {
			// 调用 封装好的 角色列表接口函数
			this.getRoleLists();
			await this.role_permission()
		},
		methods: {
			// changeinput2(e){
			// 	console.log("数据是：",e)
			// 	this.getRoleLists()
			// },
			
			// 点击了配置这个选项
			configurationstartButto(e) {
				console.log(e, this.configurationstart)
				this.configurationstart = !this.configurationstart;
			},
			//导出的操作
			// async countDowndata(){
			//   window.location.href = `${requrl}/api/backend.Log/export?operator=${this.queryParams.operator}&&
			//   start_time=${this.queryParams.start_time}&&end_time=${this.queryParams.end_time}&&module=${this.queryParams.module}`
			// }
			// 执行删除信息
			async deletedata() {
				this.$confirm('此操作将永久删除记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					if (!this.Arraydelete) {
						this.$message.error("请选择你要删除的信息~~")
						return;
					}
					this.handleDelete(this.Arraydelete);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},

			// 获取删除的选项的信息
			handleSelectionChange(val) {
				let deleteArrayS = ""
				val.forEach((item, index) => {
					if (deleteArrayS == "") { //说明是空的
						deleteArrayS += item.id
					} else {
						deleteArrayS += "," + item.id
					}
				})
				this.Arraydelete = deleteArrayS
			},

			// 点击 弹窗里面的 展开/折叠 触发的函数    树权限（展开/折叠）
			handleCheckedTreeExpand(value) {
				const treeList = this.menuOptions
				for (let i = 0; i < treeList.length; i++) {
					this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value
				}
			},
			// 点击刷新按钮
			async handshua() {
				await this.getRoleLists();
				this.$message.success('刷新成功~')
			},

			// 点击 弹窗里面的 全选/全不选 触发的函数  树权限（全选/全不选）
			handleCheckedTreeNodeAll(value) {
				this.$refs.menu.setCheckedNodes(value ? this.menuOptions : [])
			},

			// 点击 弹窗里面的 父子联动 触发的函数 树权限（父子联动）
			handleCheckedTreeConnect(value) {
				this.form.menuCheckStrictly = value
			},

			// 封装 角色列表接口函数
			async getRoleLists() {
				this.loading = true;
				console.log(this.queryParams)
				const {
					data
				} = await userlists(this.queryParams)
				this.tableData = data.rows
				this.total = data.count
				this.loading = false;
				// this.tableData = [{
				// 	log_num: "12",
				// 	module:"fewgv "
				// }]
			},

			// 封装 修改角色状态接口函数
			// async srole_status(data) {
			// 	await srole_status(data)
			// 	this.getRoleLists()
			// },

			// 封装 角色权限接口函数 /全部的角色信息
			async role_permission() {
				const {
					data
				} = await role_listAll();
				this.menuOptions = data.rows
			},

			// 封装 根据表id查询角色
			async get_role(obj) {
				const {
					data
				} = await get_role(obj)
				this.form = {
					...this.form,
					...data
				}
			},

			// 封装 添加修改角色接口函数
			async add_or_edit_role(obj) {
				let usersaveres = await user_save(obj);
				usersaveres.code == 1 ? this.$message({
					type: 'success',
					message: this.flag ? '新增成功~~' : '修改成功~~'
				}) : ''
				// 修改的时候 调用
				// if (!this.flag) {
				// 	// this.menu_access 是修改过后的  选中的节点数据，  长度大于0  说明 在点击确认之前，进行过修改节点状态， 反之 没有修改节点状态
				// 	if (this.menu_access.length > 0) {
				// 		await add_or_edit_role({
				// 			id: obj.id,
				// 			name: obj.name,
				// 			status: obj.status,
				// 			menu_access: obj.menu_access.join(',')
				// 		})
				// 		// 调用 封装好的 角色列表接口函数
				// 		this.getRoleLists()
				// 		this.menu_access.length = 0
				// 	} else {
				// 		await add_or_edit_role(this.form)
				// 		// 调用 封装好的 角色列表接口函数
				// 		this.getRoleLists()
				// 	}
				// } else {
				// 	// 新增 的时候 调用
				// 	const form = this.form
				// 	await this.handleCheckChange()
				// 	await add_or_edit_role({
				// 		...form,
				// 		menu_access: this.menu_access.join(',')
				// 	})
				// 	// 调用 封装好的 角色列表接口函数
				// 	this.getRoleLists()
				// }
			},
			
			

			// 封装 删除角色 接口函数
			async del_user(data) {
				// 说明是当页最后一个 且不是第一页
				if (this.tableData.length == 1 && this.queryParams.pageNo > 1) {
					this.queryParams.pageNo--
				}
				let del_user_res = await del_user(data);
				del_user_res.code == 1 ? this.$message({
					type: 'success',
					message: "删除成功~~"
				}) : ''
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()

				// 在删除表单最后一条数据时,自动返回上一页,但实际情况是还是删除前的页数,数据为空
				/**
				 *    this.total： 总数据条数
				 *    this.queryParams.pageSize： 每页多少条数据
				 *    this.queryParams.pageNo： 当前页码(当前是哪一页)
				 */
				const totalPage = Math.ceil((this.total - 1) / (this.queryParams.pageSize ? this.queryParams.pageSize :
					10))
				this.queryParams.pageNo = this.queryParams.pageNo > totalPage ? totalPage : this.queryParams.pageNo
				this.queryParams.pageNo = this.queryParams.pageNo < 1 ? 1 : this.queryParams.pageNo
			},

			// 顶部 新增按钮  点击触发的函数
			async handleAdd() {
				// 重置 弹窗里面的 表单数据 --开始--
				this.form = {

				}
				this.menuExpand = false
				this.menuNodeAll = false
				// 菜单列表
				this.menuOptions = []
				// 重置 弹窗里面的 表单数据 --结束--
				// 判断是新增
				this.flag = true
				// 调用 封装好的 角色权限 函数
				await this.role_permission()
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible
			},

			// 点击操作里 修改按钮 触发的函数
			async handleEdit(row) {
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible;
				this.form = row;
			},

			// 点击操作里 启动/冻结 按钮 触发的函数
			async startUsingOrFreeze(obj) {
				let status_set = obj.identitystart == 1 ? 2 : 1;
				let statusres = await srole_status({
					id: obj.id,
					identitystart: status_set
				})
				statusres.code == 1 ? this.$message({
					type: 'success',
					message: status_set == 1 ? '解冻成功' : '冻结成功'
				}) : ''
				this.getRoleLists()
			},

			// 在弹窗dialog里面的 tree 树形结构的 节点选中状态发生变化时的回调
			handleCheckChange() {
				// 获取所有选中的节点 start
				const res = this.$refs.menu.getCheckedNodes()
				this.menu_access = []
				res.forEach((item) => {
					// this.menu_access.push(item.id)
				})
				// 获取所有选中的节点 end
			},

			// 点击操作里 成员按钮 触发的函数
			async handleMember(form1) {
				let form = JSON.parse(JSON.stringify(form1))
				console.log("这个参数是1：", form, form1);
				// this.handleAdd()
				// 重置 弹窗里面的 表单数据 --开始--



				let identity_idal = [];
				if (form1.identity_id){
					form.identity_id.split(",").forEach((item) => {
						identity_idal.push(item * 1)
					})
				}
				if (!form1.identity_id) identity_idal = [];
				form.identity_id = identity_idal
				this.form = form;
				this.menuExpand = false
				this.menuNodeAll = false
				// 菜单列表
				this.menuOptions = []
				// 重置 弹窗里面的 表单数据 --结束--
				// 判断是新增
				this.flag = false
				// 调用 封装好的 角色权限 函数
				await this.role_permission()
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible
			},

			// 点击操作里 删除按钮 触发的函数
			handleDelete(id) {
				// 调用 封装好的 删除角色 接口函数
				this.del_user({
					ids: id
				})
			},

			// 点击查询按钮 触发的函数
			handleQuery() {
				this.getRoleLists()
			},

			// 点击重置按钮 触发的函数
			resetQuery() {
				// 重置数据
				this.queryParams = {
						operator: '', //操作人员
						start_time: '', //开始时间
						end_time: '', //结束时间
						module: '', //系统模块
					},

					this.dateRange = [];
				// 如果重置效果也需要数据回到最初状态，则直接调用 角色列表接口函数
				this.getRoleLists()
			},

			// 弹窗里面的取消按钮
			cancel() {
				this.dialogFormVisible = !this.dialogFormVisible
				this.flag = false
				// 重置数据
				this.form = {

				}
				this.$nextTick(() => {
					// 移除表单校验效果
					this.$refs.form.clearValidate()
				})
			},

			// 弹窗里面的确认按钮
			async confirm() {

				console.log(this.form)

				// 添加角色 触发
				// 表单校验
				await this.$refs.form.validate();
				if(this.form.identity_id)this.form.identity_id = this.form.identity_id.join(",");
				// console.log(12345,this.form)
				// // 调用 添加/修改 角色接口函数
				await this.add_or_edit_role(this.form)
				// // 移除表单校验效果
				this.$refs.form.clearValidate()
				// 修改角色 触发
				// if (!this.flag) {
				// 	// 表单校验
				// 	await this.$refs.form.validate()
				// 	// 调用 添加/修改 角色接口函数
				// 	this.add_or_edit_role({
				// 		...this.form
				// 		// menu_access: this.menu_access
				// 	})
				// 	// 移除表单校验效果
				// 	this.$refs.form.clearValidate()
				// } else if (this.flag) {
				// 	// 添加角色 触发
				// 	// 表单校验
				// 	await this.$refs.form.validate()
				// 	// 调用 添加/修改 角色接口函数
				// 	this.add_or_edit_role(this.form)
				// 	// 移除表单校验效果
				// 	this.$refs.form.clearValidate()
				// }
				// 刷新页面
				await this.getRoleLists();
				this.dialogFormVisible = !this.dialogFormVisible;
				this.flag = false
				// 重置数据
				this.form = {
					menuCheckStrictly: false
				}
			},

			// 点击切换 每页几条数据时 触发的函数
			handleSizeChange(val) {
				// 把切换的每页页数 重新赋值给 this.queryParams.pageSize
				this.queryParams.pageSize = val
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()
			},
			// 更改 前进几页 数据时 触发的函数
			handleCurrentChange(val) {
				// 把更改的页码 重新赋值给 this.queryParams.pageNo
				this.queryParams.pageNo = val
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()
			},

			// element ui 表格 格式化 函数
			formatStateCompany(row, column, cellValue) {
				if (cellValue === 1) {
					return '启用'
				} else if (cellValue === 2) {
					return '冻结'
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.wrap {
		margin: 20px;

		// 分页组件 距离上边的距离
		.el-pagination {
			margin-top: 20px;
		}
	}

	// 表头最外边框
	::v-deep .el-table__header {
		margin-top: 20px;
		border: 1px solid #ebeef5;
	}

	.dialog-footer {
		display: flex;
		justify-content: flex-end;
	}
</style>
